<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCT DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Product Data
        <br />
        <h4>Please enter product data</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    v-model="isidata.produkno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Name</label>
                  <input
                    type="text"
                    v-model="isidata.produkname"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Size</label>
                  <input
                    type="text"
                    v-model="isidata.ukuran"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Size"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Color</label>
                  <input
                    type="text"
                    v-model="isidata.warna"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Color"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SKU</label>
                  <input
                    type="text"
                    v-model="isidata.skuf"
                    autocomplete="off"
                    class="form-control"
                    placeholder="SKU"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Copyright</label>
                  <input
                    type="text"
                    v-model="isidata.haki"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Copyright"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-else-if="isFrmeditData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="hidden"
                    v-model="isidata.idxprodukno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-model="isidata.produkno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Name</label>
                  <input
                    type="text"
                    v-model="isidata.produkname"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Size</label>
                  <input
                    type="text"
                    v-model="isidata.ukuran"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Size"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Color</label>
                  <input
                    type="text"
                    v-model="isidata.warna"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Color"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SKU</label>
                  <input
                    type="text"
                    v-model="isidata.skuf"
                    autocomplete="off"
                    class="form-control"
                    placeholder="SKU"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Copyright</label>
                  <input
                    type="text"
                    v-model="isidata.haki"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Copyright"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-edit"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-8">
          <!-- <router-link to="/add-produk">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Data </i>
            </button>
          </router-link>
          &nbsp;&nbsp; -->
          <!--<router-link to="/add-produk-proses">
              <button type="button" class="btn btn-info">
                <i class="fa fa-plus"> Tambah Produk Proses </i>
              </button>
            </router-link>-->
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Product List Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Product Number"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Product Number</th>
                      <th>Product Name</th>
                      <th>Time Product</th>
                      <th>Copyright</th>
                      <!-- <th>Size</th>
                      <th>Color</th>
                      <th>SKU</th> -->
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.idx }}</td> -->
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.nama_produk }}</td>
                      <td>{{ classdata.total_cycle_time }}</td>
                      <td>{{ classdata.copyright }}</td>
                      <!-- <td>{{ classdata.size }}</td>
                      <td>{{ classdata.color }}</td>
                      <td>{{ classdata.sku }}</td> -->
                      <td>{{ classdata.notes }}</td>

                      <td>
                        <!--<router-link :to="'/edit-produk/' + classdata.id">
                          <button title="Edit Product" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>-->
                        <button
                          @click="EditData(classdata.id)"
                          title="Edit User Account"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp;
                        <router-link
                          :to="'/produk-detail/' + classdata.nomor_produk"
                        >
                          <button
                            title="List Detailed Data"
                            class="btn btn-success"
                            @click="setProduct(classdata.nomor_produk)"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>
                        <br />
                        <p></p>
                        <!--<router-link :to="'/listall-daftar-proses/' + classdata.nomor_produk">
                                     <button title="List All Process List" class="btn btn-info">
                                      <i class="fa fa-list"></i> List All Process List
                                    </button>
                                  </router-link> &nbsp;-->
                        <button
                          title="Hapus po"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Product Number</th>
                      <th>Product Name</th>
                      <th>Time Product</th>
                      <th>Copyright</th>
                      <!-- <th>Size</th>
                      <th>Color</th>
                      <th>SKU</th> -->
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";
export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        idxprodukno: "",
        produkno: "",
        produkname: "",
        ukuran: "",
        warna: "",
        skuf: "",
        haki: "",
        catatan: ""
      },
      isShow: false,
      isExist: true,
      loading: false,
      addData: true,
      isFrmeditData: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },

    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.isidata.produkno = "";
      this.isidata.produkname = "";
      this.isidata.ukuran = "";
      this.isidata.warna = "";
      this.isidata.skuf = "";
      this.isidata.haki = "";
      this.isidata.catatan = "";
      this.fetchData();
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "produk_h/getproduk_hbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idxprodukno = resp.data.data.id;
          this.isidata.produkno = resp.data.data.nomor_produk;
          this.isidata.produkname = resp.data.data.nama_produk;
          this.isidata.haki = resp.data.data.copyright;
          this.isidata.ukuran = resp.data.data.size;
          this.isidata.warna = resp.data.data.color;
          this.isidata.skuf = resp.data.data.sku;
          this.isidata.catatan = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var nop = this.isidata.produkno;
      var namap = this.isidata.produkname;
      var ukuranp = this.isidata.ukuran;
      var warnap = this.isidata.warna;
      var skufp = this.isidata.skuf;
      var hakip = this.isidata.haki;
      var catatanp = this.isidata.catatan;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ukuranp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Size can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (warnap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (skufp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SKU can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          nomor_produk: nop,
          nama_produk: namap,
          size: ukuranp,
          color: warnap,
          sku: skufp,
          copyright: hakip,
          notes: catatanp,
          kode_user: kodeuser
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/saveproduk_h";
        var urlAPIsavedata = this.$apiurl + "produk_h/saveproduk_h";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.produkno = "";
              this.isidata.produkname = "";
              this.isidata.ukuran = "";
              this.isidata.warna = "";
              this.isidata.skuf = "";
              this.isidata.haki = "";
              this.isidata.catatan = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id)
      var nop = this.isidata.produkno;
      var namap = this.isidata.produkname;
      var ukuranp = this.isidata.ukuran;
      var warnap = this.isidata.warna;
      var skufp = this.isidata.skuf;
      var hakip = this.isidata.haki;
      var catatanp = this.isidata.catatan;
      var idx = this.isidata.idxprodukno;
      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ukuranp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Size can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (warnap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (skufp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SKU can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (hakip == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Copyright can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.isidata.produkno,
          nama_produk: this.isidata.produkname,
          size: this.isidata.ukuran,
          color: this.isidata.warna,
          sku: this.isidata.skuf,
          copyright: this.isidata.haki,
          notes: this.isidata.catatan,
          kode_user: kodeuser
        };
        console.log(paramdata);
        // console.log(idx)
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/updateproduk_h/"+ idx;
        const urlAPIUpdate = this.$apiurl + "produk_h/updateproduk_h/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.produkno = "";
              this.isidata.idxprodukno = "";
              this.isidata.produkname = "";
              this.isidata.ukuran = "";
              this.isidata.warna = "";
              this.isidata.skuf = "";
              this.isidata.haki = "";
              this.isidata.catatan = "";
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getalldataproduk_h?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "produk_h/getalldataproduk_h?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getalldataproduk_h?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "produk_h/getalldataproduk_h?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/hapusproduk_h/" + id;
          const urlAPIbrgdel = this.$apiurl + "produk_h/hapusproduk_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    setProduct(product) {
      localStorage.setItem("bomproduk", product);
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
